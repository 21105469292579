import _ from 'lodash';
import React, { useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import Modal from '../../components/common/Modal';
import Input from '../../components/input/Input';
import InputFile from '../../components/input/InputFile';
import { getPreviewUrl } from '../../utils/utils';
import { WebsiteDetailFormData } from '../../validation/general/websiteDetails';

export interface CategoriesProps {
  register: UseFormRegister<Pick<WebsiteDetailFormData, 'categories'>>;
  errors: FieldErrors<Pick<WebsiteDetailFormData, 'categories'>>;
  setValue: UseFormSetValue<Pick<WebsiteDetailFormData, 'categories'>>;
  watch: UseFormWatch<Pick<WebsiteDetailFormData, 'categories'>>;
  trigger: UseFormTrigger<Pick<WebsiteDetailFormData, 'categories'>>;
}

enum ModalType {
  VIEW_CATEGORIES = 'VIEW_CATEGORIES',
  ADD_CATERGORIES = 'ADD_CATERGORIES'
}

const Categories: React.FC<CategoriesProps> = ({ register, setValue, watch, errors, trigger }) => {
  const categories = watch('categories') ?? [];
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.VIEW_CATEGORIES);
  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [addCategoryIndex, setAddCategoriesIndex] = useState<number>(0);

  const removeCategory = (indexToRemove: number) => {
    setValue(
      'categories',
      categories.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAddCategory = (index?: number) => {
    setAddCategoriesIndex(index ?? categories.length);
    setIsAddModal(index === undefined);
    setModalType(ModalType.ADD_CATERGORIES);
  };

  const handleAddCategoryCancel = () => {
    setModalType(ModalType.VIEW_CATEGORIES);
    if (isAddModal) {
      removeCategory(categories.length - 1);
    }
  };

  const name = 'categories.root';
  const error = _.get(errors, name)?.message as string;
  return (
    <div className="mb-2">
      <label htmlFor={name} className="block mb-1 font-medium">
        Categories <span className="text-red-600">*</span>
      </label>
      <button
        id={name}
        className="w-full mt-2 border border-dashed border-[#f6f6f6] bg-[#f6f6f6] text-center rounded cursor-pointer flex flex-col items-center justify-center"
        type="button"
        onClick={() => setIsModalOpen(true)}
      >
        {categories?.length === 0 ? (
          <div className="cursor-pointer py-12">
            <i className="fas fa-plus text-gray-400"></i>
            <span className="text-sm block text-gray-300 font-medium mt-1">Click here to add Categories</span>
          </div>
        ) : (
          <div className="p-2 px-4 w-full flex justify-between">
            {categories.length} {categories.length === 1 ? `category` : 'categories'} added
            <span>
              Add More <span className="fas fa-plus" />
            </span>
          </div>
        )}
      </button>
      {error && <p className="text-red-600 text-sm font-semibold">{error}</p>}
      <Modal
        header={modalType === ModalType.VIEW_CATEGORIES ? 'Categories / Tags' : 'Add Category'}
        isOpen={isModalOpen}
        onClose={() => {
          if (modalType === ModalType.ADD_CATERGORIES) {
            handleAddCategoryCancel();
          }
          setIsModalOpen(!isModalOpen);
        }}
      >
        {modalType === ModalType.VIEW_CATEGORIES ? (
          <div className="flex flex-wrap gap-4">
            <button
              className="w-60 h-32 border border-dashed border-[#f6f6f6] bg-[#f6f6f6] text-center rounded cursor-pointer flex flex-col items-center justify-center"
              onClick={() => handleAddCategory()}
            >
              <div className="cursor-pointer">
                <i className="fas fa-plus text-gray-400"></i>
                <span className="text-sm block text-gray-300 font-medium mt-1">
                  Click here to add Category
                </span>
              </div>
            </button>
            {categories?.map((category, index) => (
              <div
                className="flex relative w-60 h-32 bg-cover bg-center justify-center rounded-md overflow-hidden shadow-md"
                style={{ backgroundImage: `url(${encodeURI(getPreviewUrl(category.image))})` }}
                key={index}
                role="button"
                tabIndex={0}
                onClick={() => handleAddCategory(index)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleAddCategory(index);
                  }
                }}
              >
                <button
                  onClick={e => {
                    e.stopPropagation();
                    removeCategory(index);
                  }}
                  className="absolute top-2 right-2 bg-white text-black font-medium rounded-full px-1.5 hover:bg-red-700"
                >
                  &times;
                </button>

                {/* Black gradient on the bottom half */}
                <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-black to-background opacity-60"></div>
                <p className="font-medium text-center self-end bg-opacity-50  text-white px-2 py-1 rounded text-sm">
                  {category.title}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-3/5">
            <div className="space-y-4">
              <Input
                name={`categories.${addCategoryIndex}.title`}
                type="text"
                register={register}
                errors={errors}
                topLabel
                required
                label="Title"
                placeholder="Enter Category Title"
                className="bg-[#f6f6f6] "
              />
              <InputFile
                name={`categories.${addCategoryIndex}.image`}
                label="Image"
                required
                watch={watch}
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                addtionalText={
                  <p className="text-sm font-medium mt-1 text-gray-300">Click here to add category image</p>
                }
                accept="image/*"
              />
            </div>
            <div className="space-x-4 mt-6">
              <button
                type="button"
                onClick={async () => {
                  const isValid = await trigger([
                    `categories.${addCategoryIndex}.image`,
                    `categories.${addCategoryIndex}.title`
                  ]);
                  if (isValid) {
                    setModalType(ModalType.VIEW_CATEGORIES);
                  }
                }}
                className="font-medium bg-[#35e0a1] pl-12 pt-1 mt-2 pb-1 rounded-md pr-12"
              >
                Add
              </button>
              <button
                type="button"
                onClick={handleAddCategoryCancel}
                className="font-medium bg-gray-300 pl-12 pt-1 mt-2 pb-1 rounded-md pr-12"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Categories;
